import $ from 'jquery';
import { ApiURL } from '../config.js'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default {
  get(route, success, error) {
    $.ajax({
        type: 'GET',
        url: ApiURL() + route,
        headers: {
          'Authorization': 'Basic ' + cookies.get('session_id')
        },
        dataType: 'json',
        contentType: 'application/json'
    })
    .done(success)
    .fail(function(jqXHR, textStatus) {
        if (jqXHR.status === 401) {
          cookies.remove('session_id', { path: '/' });
          cookies.remove('is_admin', { path: '/' });
          console.log('removed cookie. Go login ')
          document.location.href = '/login?msg=Sorry+you+will+need+to+sign+in+again';
        } else if (jqXHR.status === 403) {
          console.log('Not allowed')
          document.location.href = '/login?msg=Sorry+you+are+not+allowed+to+do+that';
        } else if (jqXHR.status === 500) {
          console.log('Application Error')
        } else {
          console.log('other error')
        }
        console.log(jqXHR.status);
        error(jqXHR);
    })
  },
  delete(route, success, error) {
    $.ajax({
        type: 'DELETE',
        url: ApiURL() + route,
        headers: {
          'Authorization': 'Basic ' + cookies.get('session_id')
        },
        dataType: 'json',
        contentType: 'application/json'
    })
    .done(success)
    .fail(error)
  },
  post(route, payload, success, error) {
    $.ajax({
        type: 'POST',
        url: ApiURL() + route,
        headers: {
          'Authorization': 'Basic ' + cookies.get('session_id')
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(payload)
    })
    .done(success)
    .fail(function(jqXHR, textStatus) {
        if (jqXHR.status === 401) {
          cookies.remove('session_id', { path: '/' });
          cookies.remove('is_admin', { path: '/' });
          console.log('removed cookie. Go login ')
          document.location.href = '/login?msg=Sorry+you+will+need+to+sign+in+again';
        } else if (jqXHR.status === 403) {
          console.log('Not allowed')
          document.location.href = '/login?msg=Sorry+you+are+not+allowed+to+do+that';
        } else if (jqXHR.status === 500) {
          console.log('Application Error')
        } else {
          console.log('other error')
        }
        console.log(jqXHR.status);
        error(jqXHR);
    })
  },
  put(route, payload, success, error) {
    $.ajax({
        type: 'PUT',
        url: ApiURL() + route,
        headers: {
          'Authorization': 'Basic ' + cookies.get('session_id')
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(payload)
    })
    .done(success)
    .fail(error)
  }
}
