import React, {Component} from 'react'

class Message extends Component {
  renderSuccessMessage() {
    return <div className="alert alert-success my-alert">
        {this.props.success_message}
    </div>
  }

  renderErrorMessage() {
    return <div className="alert alert-danger my-alert">
        {this.props.error_message}
    </div>
  }

  render() {
    if (this.props.success_message) {
      return this.renderSuccessMessage()
    } else if (this.props.error_message) {
      return this.renderErrorMessage()
    } else {
        return <span></span>
    }
  }
}

export default Message;
