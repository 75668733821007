import React, { Component } from 'react';
import  { Redirect } from 'react-router-dom'

import '../App.css';

import LogInForm from '../components/account/LogInForm';
import Message from '../components/Message';


class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logged_in: false,
      success_message: null,
      error_message: null
    }
  }

  login() {
    this.setState({logged_in: true})
  }

  render() {
    if (this.state.logged_in) {
      document.location = '/';
      return <Redirect to='/?msg=LoggedIn'  />
    } else {
      return (
        <div id='container'>
          <Message success={this.state.success_message} error={this.state.error_message} />
          <LogInForm onLogin={this.login.bind(this)} location={this.props.location}/>
        </div>
      );
    }
  }
}

export default LoginPage;
