import React, { Component } from 'react';
import '../App.css';
import ProfileEditForm from '../components/account/ProfileEditForm';
import Loading from '../components/Loading';
import ApiService from '../services/api'

class ProfileEditPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error_message: null,
      session_id: null,
      profile: null
    }
  }

  componentDidMount() {
    this.getProfile()
  }

  getProfile() {
      ApiService.get(`/users/me`,
        response => {
          if (response['status'] === 'success') {
            this.setState({
              profile: response['profile'],
              loading: false
            })
          } else {
            this.setState({
              error_message: 'blah error',
              loading: false
            })
          }
        },
        error => {
          console.log(error);
        }
      )
  }
  render() {
    return (
      <div id='App' className="App">
        <div id='container'>
          <Loading loading={this.state.loading} />
          <ProfileEditForm profile={this.state.profile} error_message={this.state.error_message} />
        </div>
      </div>
    );
  }
}

export default ProfileEditPage;
