import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment'

class UserTable extends React.Component  {
  renderAccounts() {
      return this.props.accounts.map(function(account, index) {
        return <tr key={index}>
          <td><Link to={"/user/" + account.id}>{account.email_address}</Link></td>
          <td>{account.first_name}</td>
          <td>{account.last_name}</td>
          <td><Moment format="MMM Do YYYY">{account.created_at}</Moment></td>
        </tr>
      })
  }

  render() {
    if (this.props.accounts === null) {
      return <div></div>
    } else if (this.props.accounts.length === 0) {
      return <div>No Users</div>
    } else {
      return <div className='container'>
          <table className='table table-striped text-left'>
          <thead>
            <tr>
              <th scope="col">Email Address</th>
              <th scope="col">First Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Register Date</th>
            </tr>
          </thead>
          <tbody>
            {this.renderAccounts()}
          </tbody>
        </table>
      </div>
    }
  }
};

export default UserTable;
