import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import { Redirect } from 'react-router-dom'
import '../App.css';
import Banner from '../components/Banner';
import NewsletterForm from '../components/NewsletterForm';
import CardDeck from '../components/products/CardDeck'
import Footer from '../components/Footer'
import ApiService from '../services/api'


class FrontPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: null,
      location: null,
      redirect: false,
      products: []
    }
  }

  componentDidMount() {
    this.find()
  }

  find() {
    ApiService.get('/products_fp',
      response => {
        this.setState({
          products: response
        })
      },
      error => {
        console.log(error);
      }
    )
  }


  search(query, location) {
    this.setState({
      query: query,
      location: location,
      redirect: true
    })
  }

  renderRedirect() {
    if (this.state.redirect) {
      return <Redirect to={'/search?query=' + this.state.query + '&location=' + this.state.location} />
    }
  }

  render() {
    return (
      <div id='App' className="App page">
        <Helmet>
            <meta charSet="utf-8" />
            <title>Newtonys Toys - Fun STEM Toys for Kits</title>
            <meta name="description" content="Here at Newtons Toys you will find all the STEM (Science, Technology, Engineering, and Mathematics) toys your kid will love" />
            <link rel="canonical" href="https://newtonstoys.com" />
        </Helmet>
        {this.renderRedirect()}
        <div id="container">
          <Banner />
          <CardDeck products={this.state.products}/>
          <NewsletterForm />
        </div>
        <Footer />
      </div>
    );
  }
}

export default FrontPage;
