import React, { Component } from 'react';
import  { Redirect } from 'react-router-dom'
import Cookies from 'universal-cookie';

import '../App.css';

import Banner from '../components/Banner';

import LogoutMessage from '../components/account/LogoutMessage';
import ApiService from '../services/api'


class LogoutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logged_in: true
    }
  }

  componentDidMount() {
    console.log('logging out')
    this.logout();
  }

  logout() {
      const cookies = new Cookies();
      ApiService.delete('/logout',
        response => {
          cookies.remove('session_id');
          this.setState({logged_in: false});
          console.log(response);
        },
        error => {
          console.log(error);
        }
      )
  }

  render() {
    if (this.state.logged_in === false) {
      document.location = '/';
      return <Redirect to='/?msg=LoggedOut'  />
    } else {
      return (
        <div id='container'>
          <Banner />
          <LogoutMessage />
        </div>
      );
    }
  }
}

export default LogoutPage;
