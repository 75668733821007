import React, { Component } from 'react';
import ApiService from '../services/api'
import '../App.css';
//import Banner from '../components/Banner';
import ProfileView from '../components/account/ProfileView';

class ProfileViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error_message: null,
      profile: null
    }
  }

  componentDidMount() {
    this.getProfile()
  }

  getProfile() {
    ApiService.get(`/users/me`,
      response => {
        if (response['status'] === 'success') {
          this.setState({
            profile: response['profile']
          })
        } else {
          this.setState({
            error_message: 'blah error'
          })
        }
      },
      error => {
        console.log(error);
      }
    )
  }
  render() {
    return (
      <div id='App' className="App">
        <div id='container'>
          <ProfileView profile={this.state.profile} />
        </div>
      </div>
    );
  }
}

export default ProfileViewPage;
