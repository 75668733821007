import React, { Component } from 'react';
import queryString from 'query-string'
import '../App.css';
import ApiService from '../services/api'
/* import SmallSearchForm from '../components/search/SmallSearchForm' */
import CardDeck from '../components/products/CardDeck'

class SearchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: null,
      status: null,
      location: null,
      products: []
    }
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search)
    this.search(values.name)
  }

  search(name) {
    this.setState({status: 'loading'})
    ApiService.get('/search?name=' + name,
      response => {
        this.setState({
          status: 'idle',
          name: name,
          products: response['results']
        })
      },
      error => {
        console.log(error);
      }
    )
  }

  render() {
    return (
      <div id='App' className="App">
        <div id='container'>
          <h1>Search Results</h1>
          <CardDeck products={this.state.products}/>
        </div>
      </div>
    );
  }
}

export default SearchPage;
