import React, { Component } from 'react';
import queryString from 'query-string'
import '../../App.css';
import Loading from '../../components/Loading';
import UserTable from '../../components/account/UserTable'
import ApiService from '../../services/api'

class UserListingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      user_type: 'employer',
      users: null,
    }
  }

  componentWillReceiveProps() {
    const values = queryString.parse(this.props.location.search)
    this.setState({user_type: values.user_type, loading: true})
    this.findUsers(values.user_type)
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search)
    this.setState({user_type: values.user_type, loading: true})
    this.findUsers(values.user_type)
  }

  findUsers(user_type) {
    ApiService.get('/users?user_type=' + user_type,
      response => {
        this.setState({
          users: response,
          loading: false
        })
      },
      error => {
        console.log(error);
      }
    )
  }

  render() {
    return (
      <div id='App' className="App">
        <div id="container">
          <h1>User List ({this.state.user_type.replace('_', ' ')})</h1>
          <Loading loading={this.state.loading} />
          <UserTable accounts={this.state.users} />
        </div>
      </div>
    );
  }
}

export default UserListingPage;
