import React, { Component } from 'react';
import '../App.css';
import Banner from '../components/Banner';

class AboutUsPage extends Component {
  render() {
    return (
      <div id='App' className="App page">
        <div id="container">
          <Banner />
          <h1 className='mt-4'>About Us</h1>
          <div className='container'>
            <h3 className='mt-4'>
              We love science and toys.  Here you will find a curated collection of educational toys that are fun for the whole family.
            </h3>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUsPage;
