import React from 'react';
import { Link } from 'react-router-dom';

class UserDetails extends React.Component  {
  render() {
    if (this.props.profile === null) {
      return <div>
        <div className='text-center'>
          <h2>Loading...</h2>
        </div>
      </div>
    } else {
      return <div id='body'>
        <div className='container'>

          <div className='row mx-auto clear-top mt-3'>
            <div className="col-md-6 mx-auto">
              <div className="card card-body">
                <h3 className="text-center mb-4">User Profile</h3>
                <fieldset className='text-left'>
                    <div className="form-group has-error">
                      <label forhtml="email_address" className='font-weight-bold'>Email Address</label>
                      <div name='email_address'>{this.props.profile.email_address}</div>
                    </div>
                    <div className="form-group has-error">
                      <label forhtml="email_address" className='font-weight-bold'>First Name</label>
                      <div>{this.props.profile.first_name}</div>
                    </div>
                    <div className="form-group has-error">
                      <label forhtml="email_address" className='font-weight-bold'>Last Name</label>
                      <div>{this.props.profile.last_name}</div>
                    </div>
                    <div className="form-group has-error">
                      <label forhtml="linked_in_url" className='font-weight-bold'>Linked-In</label>
                      <div>{this.props.profile.linked_in_url}</div>
                    </div>
                </fieldset>
                <Link to='/profile_edit' className='btn btn-primary'>Edit Profile</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  }
};

export default UserDetails;
