import React, {Component} from 'react'
import ProductCard from './ProductCard'

class CardDeck extends Component {
  renderCards() {
    return this.props.products.map(function(product, index) {
      return <ProductCard key={index} product={product} />
    })
  }

  render() {
    if (this.props.products === null) {
      return <span></span>
    } else if (this.props.products.length > 0) {
      return <div className='container'>
          <div className='row px-0 mb-5 mt-3'>
            {this.renderCards()}
          </div>
      </div>
    } else {
      return <span>Nothing here</span>
    }
  }
}

export default CardDeck;
