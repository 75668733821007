import React, { Component } from 'react';
import '../App.css';
import Banner from '../components/Banner';
import Footer from '../components/Footer';

class ContactPage extends Component {
  render() {
    return (
      <div id='App' className="App page">
        <div id="container">
          <Banner />
          <h1>Contact Us</h1>
          <h4>Email us at <a href='mailto:support@newtonstoys.com'>support@newtonstoys.com</a></h4>
          <Footer />
        </div>
      </div>
    );
  }
}

export default ContactPage;
