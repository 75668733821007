import React, { Component } from 'react';
import '../App.css';
import SignUpForm from '../components/account/SignUpForm';

class SignUp extends Component {
  render() {
    return (
      <div id="container">
        <SignUpForm />
      </div>
    );
  }
}

export default SignUp;
