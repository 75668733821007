import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import ReactGA from 'react-ga';
import createBrowserHistory from 'history/createBrowserHistory'


import './index.css';
//import * as serviceWorker from './serviceWorker';
import App from './App';
import ScrollToTop from './ScrollToTop'

ReactGA.initialize('UA-135615669-1'); //Unique Google Analytics tracking number
ReactGA.pageview('/');

const history = createBrowserHistory()
history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});
//import registerServiceWorker from './registerServiceWorker';



ReactDOM.render(
    <Router onUpdate={() => window.scrollTo(0, 0)} history={createBrowserHistory()}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </Router>
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
//registerServiceWorker();
