import React, {Component} from 'react'

class Loading extends Component {
  render() {
    if (this.props.loading) {
      return <span>
        <i className='fa fa-spinner fa-spin fa-3x'></i>
      </span>
    } else {
      return <span></span>
    }
  }
}

export default Loading;
