import React, {Component} from 'react'
import ApiService from '../services/api'

class NewsletterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      signed_up: false
    }
  }

  subscribe() {
    let email_address = document.getElementById('newsletter_email_address').value;
    ApiService.post(`/newsletter_subscription/` + email_address, {},
      response => {
        this.setState({
          loading: false,
          signed_up: true
        })
      },
      error => {
        console.log(error);
      }
    )
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.subscribe();
    }
  }

  render() {
    if (this.state.signed_up) {
      return <div>
        <div className='container border rounded p-5 nt-newsletter'>
          <h2 className=''>Thanks for signing up for our Newsletter</h2>
        </div>
      </div>
    } else {
      return <div>
        <div className='container border rounded p-5 nt-newsletter'>
          <h2 className=''>Signup for our Newsletter</h2>
          <div className='form-row align-items-center'>
            <div className="col-sm-4 input-group m-2 mx-auto">
              <div className="input-group-prepend">
                <span className="input-group-text"><i className='fa fa-envelope'/></span>
              </div>
              <input id='newsletter_email_address' required type="email" className="form-control" placeholder="Email Address" onKeyPress={this.handleKeyPress.bind(this)} />
              <button className='btn btn-primary' onClick={this.subscribe.bind(this)}>Sign-up</button>
              <small id="emailHelp" className="form-text text-muted">We will never share your email with anyone else.</small>
            </div>
          </div>
        </div>
      </div>
    }
  }
}

export default NewsletterForm;
