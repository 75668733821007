import React from 'react';
import ApiService from '../../services/api'
import Cookies from 'universal-cookie';
import queryString from 'query-string'
import Message from '../../components/Message'
import { Link } from 'react-router-dom';


class LoginForm extends React.Component  {
  constructor(props) {
    super(props);

    const values = queryString.parse(this.props.location.search)

    this.state = {
      error_message: values.msg,
      login_success: null,
      session_id: null
    }
  }

  login() {
      var form = {
        'email_address': document.getElementById('email_address').value,
        'password': document.getElementById('password').value
      }
      ApiService.post('/login', form,
        response => {
          if (response['status'] === 'not authenticated') {
            this.setState({
              login_success: false,
              error_message: response['message']
            })
          } else {
            this.setState({
              login_success: true,
              error_message: null,
              session_hash: response['session']['session_hash']
            })
            const cookies = new Cookies();
            cookies.set('session_id', response['session']['session_hash'], { path: '/' });
            cookies.set('is_admin', response['user']['is_admin'], { path: '/' });
            this.props.onLogin();
          }
        },
        error => {
          console.log(error);
        }
      )
      return false;
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.login();
    }
  }

  render() {
    return <div id='body' className='row mx-auto container clear-top mt-3'>
      <div className="col-md-6 mx-auto">
          <div className="card card-body">
              <h3 className="text-center mb-4">Login</h3>
              <Message error_message={this.state.error_message} />
              <fieldset>
                  <div className="form-group has-error">
                      <input id='email_address' className="form-control input-lg" placeholder="E-mail Address" name="email" type="text" autoFocus={true} />
                  </div>
                  <div className="form-group has-success">
                      <input id='password' className="form-control input-lg" placeholder="Password" name="password" type="password" onKeyPress={this.handleKeyPress.bind(this)} />
                  </div>
                  <button className="btn btn-lg btn-primary btn-block" type="submit" onClick={this.login.bind(this)}>Log In</button>
                  <div className="mt-4">
                    <Link to='/forgot_password'>I forgot my password</Link>
                  </div>
                  <div className="mt-4">
                    <Link to='/signup'>Need to Create an Account? Click here.</Link>
                  </div>
              </fieldset>
          </div>
      </div>
  </div>
  }
};

export default LoginForm;
