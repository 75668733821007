import React, {Component} from 'react'
import ApiService from '../../services/api'
import Message from '../../components/Message';

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error_message: null,
      success_message: null
    }
  }

  signUp() {
      var form_data = {
        'email_address': document.getElementById('email_address').value,
        'first_name': document.getElementById('first_name').value,
        'last_name': document.getElementById('last_name').value,
        'password1': document.getElementById('password').value,
        'password2': document.getElementById('password_confirm').value,
        'user_type': 'user'
      }
      ApiService.post('/users', form_data,
        response => {
          console.log(response);
          if (response['success'] === true) {
            this.setState({
              success_message: 'Signup Successful. Please check your email for confirmation (or just login)',
              error_message: null
            })
          } else {
            this.setState({
              success_message: null,
              error_message: response['error_message']
            })
          }
        },
        error => {
          console.log(error);
        }
      )
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.signUp();
    }
  }

  render() {
    return (
      <div id='body' className="row container mx-auto mt-3">
        <div className="col-md-6 mx-auto">
          <div className="card card-body">
            <h3 className="text-center mb-4">Sign-up</h3>
            <Message success_message={this.state.success_message} error_message={this.state.error_message} />
            <fieldset>
              <div className="form-group has-error">
                <input id='email_address' className="form-control input-lg" placeholder="E-mail Address" name="email_address" type="text" autoFocus={true} />
              </div>
              <div className="form-group has-error">
                <input id='first_name' className="form-control input-lg" placeholder="First Name" name="first_name" type="text" />
              </div>
              <div className="form-group has-error">
                <input id='last_name' className="form-control input-lg" placeholder="Last Name" name="last_name" type="text" />
              </div>
              <div className="form-group has-success">
                <input id='password' className="form-control input-lg" placeholder="Password" name="password" type="password" />
              </div>
              <div className="form-group has-success">
                <input id='password_confirm' className="form-control input-lg" placeholder="Confirm Password" name="confirm" type="password" onKeyPress={this.handleKeyPress.bind(this)} />
              </div>
              <input className="btn btn-lg btn-primary btn-block" value="Sign Me Up" type="submit" onClick={this.signUp.bind(this)}/>
            </fieldset>
          </div>
        </div>
      </div>
  )
  }
}

export default SignUpForm;
