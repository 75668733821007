import React, { Component } from 'react';
//import ReactDOM from 'react-dom';
//import createBrowserHistory from 'history/createBrowserHistory'

import './index.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/font-awesome/css/font-awesome.min.css';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import {CSSTransition, TransitionGroup} from 'react-transition-group'

import Header from './components/Header';
/*import Footer from './components/Footer';*/

// General Pages
import FrontPage from './pages/FrontPage';
import ContactPage from './pages/ContactPage';
import AboutUsPage from './pages/AboutUsPage';
import PrivacyPolicy from './components/Privacy';
import TermsOfUse from './components/Terms';

// Signup / Signin Pages
import SignUp from './pages/SignUp';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage'
import ResetPasswordPage from './pages/ResetPasswordPage'

// User Pages
import UserDetailPage from './pages/UserDetailPage'
import ProfileViewPage from './pages/ProfileViewPage'
import ProfileEditPage from './pages/ProfileEditPage'

import ProductPage from './pages/products/ProductPage'
import SearchPage from './pages/SearchPage'


// Admin Pages
import UserListingPage from './pages/admin/UserListingPage'

class App extends Component {
  render() {
    return (
      <div>
          <Header />
          <Switch>
            <Route render={({location}) => (
              <TransitionGroup>
                <CSSTransition key={location.key} timeout={300} classNames="fade" >
                  <Switch location={location}>

                    <Route exact path="/" component={FrontPage} />
                    <Route path="/contact" component={ContactPage} />
                    <Route path="/about" component={AboutUsPage} />
                    <Route path="/privacy" component={PrivacyPolicy} />
                    <Route path="/terms" component={TermsOfUse} />

                    <Route path="/signup" component={SignUp} />

                    <Route path="/login" component={LoginPage} />
                    <Route path="/logout" component={LogoutPage} />
                    <Route path="/forgot_password" component={ForgotPasswordPage} />
                    <Route path="/password_reset" component={ResetPasswordPage} />

                    <Route path="/product/:product_id" component={ProductPage} />
                    <Route path="/search" component={SearchPage} />

                    <Route path="/user/:user_id" component={UserDetailPage} />
                    <Route path="/admin/users" component={UserListingPage} />

                    <Route path="/profile_view" component={ProfileViewPage} />
                    <Route path="/profile_edit" component={ProfileEditPage} />

                    <Route path='/link/:link_id' component={() => { window.location = 'https://www.google.com'; return null;} }/>


                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            )} />

          </Switch>

      </div>
    )
  }
}

export default App;
