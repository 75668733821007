import React, {Component} from 'react'
import { Link } from 'react-router-dom';
import './ProductCard.css';

class ProductCard extends Component {
  render() {
    return (
      <div className="cardContainer col-12 col-sm-6 col-md-4 col-lg-3">
        <Link to={"/product/" + this.props.product.id}>
            <div className="productCard text-left d-block mb-4 bg-white br-sm p-3">
              <img className="mx-auto img-fluid" src={this.props.product.image_url} alt={this.props.product.name} />
              <h5 className="name">{this.props.product.name}</h5>
              <p className="description">{this.props.product.description}</p>
              <div>
                <span className="price">${this.props.product.price}</span>
                <button className="btn btn-primary pull-right">View</button>
              </div>
            </div>
        </Link>
      </div>
    )
  }
}

export default ProductCard;
