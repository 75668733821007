import React from 'react';
import ApiService from '../../services/api';
import Message from '../../components/Message';

class ChangePassword extends React.Component  {
  constructor(props) {
    super(props);
    this.state = {
      error_message: null,
      success_message: null,
      success: null
    }
  }

  resetPassword() {
      var form_data = {
        'password1': document.getElementById('password').value,
        'password2': document.getElementById('password_confirm').value
      }
      ApiService.post('/reset_password', form_data,
        response => {
          console.log(response);
          if (response['success'] === true) {
            this.setState({
              password_change_success: true,
              success_message: 'Password Change Successfully',
              error_message: null
            })
          } else {
            this.setState({
              password_change_success: false,
              error_message: response['error_message']
            })
          }
        },
        error => {
            this.setState({
              password_change_success: false,
              error_message: 'Sorry, there was an issue. Please try again'
            })
          console.log(error);
        }
      )
  }

  togglePasswordChangeForm() {
    this.setState({show_password_reset: !this.state.show_password_reset})
  }

  renderPasswordResetForm() {
    if (this.state.success_message) {
      return <fieldset>
        <Message success_message={this.state.success_message} />
      </fieldset>
    }
    if (this.state.show_password_reset) {
      return <fieldset>
        <Message error_message={this.state.error_message} />
        <div className="form-group has-success">
          <input id='password' className="form-control input-lg" placeholder="Password" name="password" type="password" />
        </div>
        <div className="form-group has-success">
          <input id='password_confirm' className="form-control input-lg" placeholder="Confirm Password" name="confirm" type="password" />
        </div>
        <button className='btn btn-primary w-40 m-1' onClick={this.resetPassword.bind(this)} >Change Password</button>
        <button className='btn btn-danger w-40 m-1' onClick={this.togglePasswordChangeForm.bind(this)} >Cancel</button>
      </fieldset>
    } else {
      return <button className='btn btn-primary' onClick={this.togglePasswordChangeForm.bind(this)} >Change Password</button>
    }
  }

  render() {
    return this.renderPasswordResetForm()
  }
};

export default ChangePassword;
