import React, {Component} from 'react'
import { Link } from 'react-router-dom';
import banner_image from './../images/banner.jpg';

class Banner extends Component {
  render() {
    return <div className='container'>
      <div className='row'>
        <Link to='/'>
          <div className='col'>
            <img src={banner_image} className="App-banner" alt="logo" />
          </div>
        </Link>
      </div>
    </div>
  }
}

export default Banner;
