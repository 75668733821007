import React, {Component} from 'react'
import { Link } from 'react-router-dom';

import Cookies from 'universal-cookie';
import logo_image from './../images/logo.jpg';

class Header extends Component {
  constructor(props) {
    const cookies = new Cookies();

    super(props);
    this.state = {
      isToggleOn: false,
      searchQuery: '',
      session_id: cookies.get('session_id'),
      is_admin: cookies.get('is_admin')
    };
    console.log(this.state)
  }

  showDropdown(e) {
    e.preventDefault();
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn
    }));
  }

  menuItems() {
    if (this.state.is_admin === "1") {
      return <div>
        <Link to='/admin/users'><span className="dropdown-item">User Accounts</span></Link>
        <Link to='/logout'><span className="dropdown-item">Logout</span></Link>
      </div>
    } else {
      return <div>
        <Link to='/profile_view'><span className="dropdown-item">My Profile</span></Link>
        <Link to='/logout'><span className="dropdown-item">Logout</span></Link>
      </div>
    }
  }

  login_logout_button() {
    //console.log(cookies.get('session_id'))
    if (this.state.session_id === undefined) {
      return <div>
          <span className='font-weight-bold m-1'>
            <span>
              <Link to='/login'>Login</Link>
            </span>
          </span>
          |
          <span className='font-weight-bold m-1'>
            <Link to='/signup'>Signup</Link>
          </span>
        </div>
    } else {
      const classDropdownMenu = 'dropdown-menu dropdown-menu-right' + (this.state.isToggleOn ? ' show' : '')
      return <div className="btn-group">
        <button type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={(e) => {this.showDropdown(e)}}>
          Menu
        </button>
        <div className={classDropdownMenu}>
          {this.menuItems()}
        </div>
      </div>
    }
  }



  search() {
    document.location = '/search?name=' + document.getElementById('search_query').value
    /*
    let query = document.getElementById('search_query').value;
    ApiService.get(`/search/` + query,
      response => {
        this.setState({
          results: false
        })
      },
      error => {
        console.log(error);
      }
    )*/
  }

  handleKeyPress(e) {
    this.setState({'searchQuery': document.getElementById('search_query').value})
    if (e.key === 'Enter') {
      this.search();
    }
  }

  renderSearchBar() {
    return <div className='form-row align-items-center'>
      <div className="col input-group m-2 mx-auto">
        <div className="input-group-prepend">
          <span className="input-group-text"><i className='fa fa-search'/></span>
        </div>
        <input id='search_query' required type="text" className="form-control" placeholder="Search for Deals" onKeyPress={this.handleKeyPress.bind(this)} />
        <button className='btn btn-primary' onClick={this.search.bind(this)}>Search</button>
      </div>
    </div>
  }

  render() {
    return <nav id='header' className="Xsticky-top">
      <div className='container header-row p-3'>
        <div className='row'>

          <div className='col-3 text-left align-self-center'>
            <Link to='/'>
              <img src={logo_image} className="App-logo" alt="Made To Buy" />
            </Link>
          </div>

          <div className='col-6 align-self-center'>
            {this.renderSearchBar()}
          </div>

          <div className='col-3 text-right mx-auto align-self-center'>
            {this.login_logout_button()}
          </div>
        </div>
      </div>
    </nav>
  }
}

export default Header;
