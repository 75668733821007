import React, { Component } from 'react';
import ApiService from '../services/api'
import '../App.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Loading from '../components/Loading';

import UserDetails from '../components/account/UserDetails'

class UserDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error_message: null,
      profile: null,
      applications: []
    }
  }

  componentDidMount() {
    const { user_id } = this.props.match.params;
    this.getProfile(user_id);
  }

  getProfile(user_id, session_id) {
    ApiService.get(`/users/` + user_id,
      response => {
        if (response['status'] === 'success') {
          this.setState({
            profile: response['profile'],
            applications: response['applications'],
            loading: false
          })
        } else {
          this.setState({
            error_message: 'blah error',
            loading: false
          })
        }
      },
      error => {
        console.log(error);
      }
    )
  }

  renderBody() {
    if (this.state.profile) {
      return <UserDetails profile={this.state.profile} applications={this.state.applications} />
    } else {
      return <div></div>
    }
  }

  render() {
    return (
      <div id='App' className="App">
        <div id='container'>
          <Header />
          <Loading loading={this.state.loading} />
          { this.renderBody() }
          <Footer />
        </div>
      </div>
    );
  }
}

export default UserDetailPage;
