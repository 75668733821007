import React from 'react';
import { Redirect } from 'react-router-dom'
import Message from '../../components/Message';
import Loading from '../../components/Loading';
import ChangePassword from '../../components/account/ChangePassword'

import ApiService from '../../services/api'

class ProfileEditForm extends React.Component  {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      profile: null,
      form_changed: false,
      show_password_reset: false,
      password_change_success: false,
      updated: false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    return {
      profile: nextProps.profile
    }
  }

  handleChange(attribute, value) {
    var state = this.state;
    state.profile[attribute] = value;
    this.setState(state)
    this.setState({form_changed: true})
  }

  save() {
    this.setState({saving: true});
    ApiService.post(`/users/me`, this.state.profile,
      response => {
        console.log(response);
        this.setState({updated: true, saving: false})
      },
      error => {
        console.log(error);
        this.setState({saving: false})
      }
    )
  }

  saveButtonText() {
    if (this.state.saving) {
      return <span>Saving</span>
    } else {
      return <span>Save</span>
    }
  }

  render() {
    if (this.state.updated) {
      return <Redirect to='/profile_view?msg=ProfileUpdated'  />
    } else if (this.props.profile === null) {
      return <div>
        <Message success_message={this.state.success_message} error_message={this.state.error_message} />
      </div>
    } else {
      return <div id='body' className='row mx-auto container clear-top mt-3'>
        <div className="col-md-6 mx-auto">
            <div className="card card-body">
              <h3 className="text-center mb-4">Edit Profile</h3>
                <fieldset className="text-left">
                    <div className="form-group has-error">
                        <label forhtml="email_address">Email Address</label>
                        <input disabled id='email_address' className="form-control input-lg" name="email" type="text" autoFocus={true} value={this.state.profile.email_address} />
                    </div>
                    <div className="form-group has-success">
                        <label forhtml="first_name">First Name</label>
                        <input id='first_name' className="form-control input-lg" value={this.state.profile.first_name} name="first_name" type="text"
                               onChange={(e) => this.handleChange('first_name', e.target.value) } />
                    </div>
                    <div className="form-group has-success">
                        <label forhtml="last_name">Last Name</label>
                        <input id='last_name' className="form-control input-lg" value={this.state.profile.last_name} name="last_name" type="text"
                               onChange={(e) => this.handleChange('last_name', e.target.value) } />
                    </div>
                    <button className='btn btn-primary' disabled={this.state.saving} onClick={this.save.bind(this)} >{this.saveButtonText()}</button>
                    <Loading loading={this.state.saving} />
                </fieldset>
            </div>
            <div className="card card-body">
              <ChangePassword />
            </div>
        </div>
    </div>
    }
  }
};

export default ProfileEditForm;
