import React from 'react';
import ApiService from '../../services/api';
import Message from '../../components/Message';

class ForgotPasswordPage extends React.Component  {
  constructor(props) {
    super(props);
    this.state = {
      error_message: null,
      success_message: null,
      success: null
    }
  }

  forgotPassword() {
      var form_data = {
        'email_address': document.getElementById('email_address').value
      }
      ApiService.post('/forgot_password', form_data,
        response => {
          console.log(response);
          if (response['success'] === true) {
            this.setState({
              success: true,
              success_message: 'Please check your email for instructions to reset your password',
              error_message: null
            })
          } else {
            this.setState({
              success: false,
              error_message: response['error_message']
            })
          }
        },
        error => {
          console.log(error);
        }
      )
  }

  render() {
    return <div id='body' className="row mx-auto container clear-top mt-3">
      <div className="col-md-6 mx-auto">
        <div className="card card-body">
          <h3 className="text-center mb-4">Forgot your password?<br /> We will send you a password reset link</h3>
          <Message success_message={this.state.success_message} error_message={this.state.error_message} />
          <fieldset>
            <div className="form-group has-error">
              <input className="form-control input-lg" placeholder="E-mail Address" id="email_address" type="text" />
            </div>
            <input className="btn btn-lg btn-primary btn-block" value="Send me instructions" type="submit" onClick={this.forgotPassword.bind(this)}/>
          </fieldset>
        </div>
      </div>
  </div>
  }
};

export default ForgotPasswordPage;
