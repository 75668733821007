import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import ApiService from '../../services/api';
import { Redirect } from 'react-router-dom'
import { ApiURL } from '../../config.js'
import Footer from '../../components/Footer'

class ProductPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      link_clicked: false,
      link_url: '/link/google',
      added_to_cart: false,
      error_message: null,
      product: null
    }
  }

  getProduct(product_id) {
    ApiService.get(`/products/` + product_id,
      response => {
        this.setState({
          product: response,
          loading: false
        })
      },
      error => {
        console.log(error);
      }
    )
  }

  addToCart() {
    var payload = {
      action: 'add_product',
      product_id: this.state.product.id,
      quantity: 1
    }
    ApiService.post(`/cart`, payload,
      response => {
        this.setState({
          added_to_cart: true,
        })
      },
      error => {
        console.log(error);
      }
    )
  }

  componentDidMount() {
    const { product_id } = this.props.match.params;
    this.getProduct(product_id);
  }

  render() {
    if (this.state.link_clicked) {
      return <Redirect to={this.state.link_url} />
    } else if (this.state.product) {
      return (
        <div id='App' className="App page">
          <Helmet>
              <meta charSet="utf-8" />
              <title>Newtons Toys - {this.state.product.name}</title>
              <meta name="description" content={"Your child will love this STEM toy.  It will encourage them to learn about science, engineering, mathematics, and engineering. " + this.state.product.name} />
              <link rel="canonical" href="https://newtonstoys.com" />
          </Helmet>
          <div className="container">
            <h1 className='mt-4'>{this.state.product.name}</h1>
            <div className='row'>
              <div className="col-md-6">
                <img className="mx-auto img-fluid" src={this.state.product.image_url} alt={this.state.product.name} />
              </div>
              <div className="col-md-6">
                <div className=" text-left d-block mb-4 bg-white br-sm p-3">
                  <p className="description">{this.state.product.description}</p>
                  <div>
                    <span className="price">${this.state.product.price}</span>
                    <br />
                    <a className='btn btn-primary' rel='nofollow noopener noreferrer' href={ApiURL() + '/link/' + this.state.product.id} target='_blank'>Check it out</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    } else {
      return <div>
        <Footer />
      </div>
    }
  }
}

export default ProductPage;
