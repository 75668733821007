import React, {Component} from 'react'
import { Link } from 'react-router-dom';

class Footer extends Component {
  render() {
    return <footer id='footer' className='footer'>
      <div className='container mx-auto'>
        <div className='row m-4'>
          <div className='col-sm-4 col-xs-12'>
            <div className='list-group list-group-flush'>
              <Link to="/about">
                <span className='border border-primary list-group-item m-1 rounded'>About</span>
              </Link>
              <Link to="/contact">
                <span className='border border-primary list-group-item m-1 rounded'>Contact</span>
              </Link>
            </div>
          </div>
          <div className='col-sm-4 col-xs-12'>
            <div className='list-group list-group-flush'>
              <Link to='/privacy'><span className='border border-primary list-group-item m-1 rounded'><i className='fa fa-facebook'/></span></Link>
              <Link to='/terms'><span className='border border-primary list-group-item m-1 rounded'><i className='fa fa-twitter'/></span></Link>
            </div>
          </div>
          <div className='col-sm-4 col-xs-12'>
            <div className='list-group list-group-flush'>
              <Link to="/privacy">
                <span className='border border-primary list-group-item m-1 rounded'>Privacy Policy</span>
              </Link>
              <Link to="/terms">
                <span className='border border-primary list-group-item m-1 rounded'>Terms of Use</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='text-center mx-auto'>&copy; 2019, NewtonsToys.com (v1.0)</div>
      </div>
    </footer>
  }
}

export default Footer;
