import React, { Component } from 'react';
import '../App.css';
import ForgotPasswordForm from '../components/account/ForgotPasswordForm';

class ForgotPasswordPage extends Component {
  render() {
    return (
      <div id='container'>
        <ForgotPasswordForm />
      </div>
    );
  }
}

export default ForgotPasswordPage;
