import React from 'react';


class LogoutMessage extends React.Component  {
  render() {
    return <div id='body' className='row mx-auto container clear-top mt-3'>
      <div className="col-md-6 mx-auto">
          <div className="card card-body">
              <h3 className="text-center mb-4">You have been logged out</h3>
          </div>
      </div>
  </div>
  }
};

export default LogoutMessage;
