import React, { Component } from 'react';
import queryString from 'query-string'
import '../App.css';
import ApiService from '../services/api'
import Message from '../components/Message';

class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      reset_success: null,
      error_message: null
    }
  }

  resetPassword() {
      var form_data = {
        'password1': document.getElementById('password').value,
        'password2': document.getElementById('password_confirm').value,
        'reset_token': this.state.token
      }
      ApiService.post('/reset_password', form_data,
        response => {
          console.log(response);
          if (response['success'] === true) {
            this.setState({
              reset_success: true,
              error_message: null
            })
          } else {
            this.setState({
              reset_success: false,
              error_message: response['error_message']
            })
          }
        },
        error => {
            this.setState({
              reset_success: false,
              error_message: 'Sorry, there was an issue. Please try again'
            })
          console.log(error);
        }
      )
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search)
    this.setState({token: values.token})
  }

  render() {
    return (
      <div id='container'>
        <div className='container'>
          <div id='body' className="row mt-3">
            <div className="col-md-6 mx-auto">
              <div className="card card-body">
                <h3 className="text-center mb-4">Reset Your Password</h3>
                <Message success_message={this.state.reset_success} error_message={this.state.error_message} />
                <fieldset>
                  <div className="form-group has-success">
                    <input id='password' className="form-control input-lg" placeholder="Password" name="password" type="password" />
                  </div>
                  <div className="form-group has-success">
                    <input id='password_confirm' className="form-control input-lg" placeholder="Confirm Password" name="confirm" type="password" />
                  </div>
                  <input className="btn btn-lg btn-primary btn-block" value="Reset Your Password" type="submit" onClick={this.resetPassword.bind(this)}/>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPasswordPage;
